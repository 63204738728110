import { connect } from 'react-redux'
import {
  Route,
  RouteProps,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom'
// import history from '../util/history'
import { SystemState } from '../features/session/types'

interface StateProps {
  authenticated: boolean | null
}

type Props = RouteProps & StateProps & RouteComponentProps

class AuthorizedRouteHOC extends Route<Props> {
  componentDidMount() {
    this.checkAuth()
  }

  componentDidUpdate() {
    this.checkAuth()
  }

  checkAuth() {
    const { history } = this.props
    if (!this.props.authenticated) {
      history.push({
        pathname: '/lock',
        search: `?return_to=${encodeURIComponent(
          this.props.location?.pathname || ''
        )}`,
      })
    }
  }
}

function mapStateToProps(state: SystemState | any) {
  /**
   * TODO: 对于 auth 状态的认定还有待规范，这里的判断只能是临时用一下，依赖 user 模型的定义而变化
   */
  const authenticated = !!state.session.user?.uid
  return {
    authenticated,
  }
}

export default withRouter(
  connect<StateProps>(mapStateToProps)(AuthorizedRouteHOC)
)
