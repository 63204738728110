import React, { Dispatch, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
// import RichTextEditor from 'react-rte'
import { Button } from 'evergreen-ui'

import { selectors as noteSelectors } from '../../../features/note'
import { Note, NoteActionTypes } from '../../../features/note/types'

import './NoteList.scss'

const PanelHeader = styled.div`
  background-color: #b0c3dc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin: 0;
    padding: 0.8rem 1rem;
  }
  .tools {
    padding: 0 1rem;
  }
`

const PanelBody = styled.div`
  padding: 1rem;
  max-height: 150px;
  overflow: auto;
`

const Panel = styled.div`
  background: #e7dc6a;
  /* background: #fffbe5; */
  border: 1px solid #f2f2f2;
  margin-top: 1.5rem;
  &.closed ${PanelBody} {
    display: none;
  }
`

const NotePanel: React.FC<{ note: Note }> = ({ note }) => {
  // const [editorState, setEditorState] = React.useState(
  //   RichTextEditor.createValueFromString(note.content, 'html')
  // )

  const dispatch = useDispatch<Dispatch<NoteActionTypes>>()

  const handleDelete = useCallback(
    () =>
      dispatch({
        type: 'DELETE_NOTE',
        payload: {
          id: note.id,
        },
      }),
    [note, dispatch]
  )

  return (
    <Panel>
      <PanelHeader>
        <h4>日期: {note.date}</h4>
        <div className="tools">
          <Button appearance="minimal" intent="danger" onClick={handleDelete}>
            删除
          </Button>
        </div>
      </PanelHeader>
      <PanelBody>
        {/* <RichTextEditor
          placeholder="Tell me something... (Click to write)"
          value={editorState}
          // onChange={setEditorState}
        /> */}
        <div
          className="note-entry"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: note.content }}
        />
      </PanelBody>
    </Panel>
  )
}

const NoteList: React.FC = () => {
  const notes = useSelector(noteSelectors.getNotes)

  if (notes?.length === 0) {
    return (
      <Panel>
        <PanelBody>
          <h4>还没有任何 note ~</h4>
        </PanelBody>
      </Panel>
    )
  }

  return (
    <>
      <h2>Notes 本地存根</h2>
      {notes.map((n) => (
        <NotePanel key={n.id} note={n} />
      ))}
    </>
  )
}

export default NoteList
