import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectors as sessionSelectors } from '../../features/session'

import { Client } from '../../client'

export const client = new Client()
// client.connect()

const Message = () => {
  const user = useSelector(sessionSelectors.getUser)
  useEffect(() => {
    if (user) {
      // TODO:
    }
  }, [user])

  return <div>Message</div>
}

export default Message
