import React, { useReducer } from 'react'
import styled from 'styled-components'
import { Button, Pane, Heading, Alert } from 'evergreen-ui'
import axios from 'axios'

import Item, { UploadStage } from './components/Item'
import request from '../../util/request'
import { byteToText } from '../../util/fmt'
import { MaxFileSizeInBytes, API_ROOT } from '../../consts'

// https://api.xzd.co/upload
// https://uploadokku.xzd.co:2087/upload
const UPLOAD_API = `${API_ROOT}/upload`

// 单个文件大小限制
const MAX_FILE_SIZE = MaxFileSizeInBytes

const Page = styled.div`
  height: 100%;
  width: 650px;
  margin: 0 auto;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  @media (max-width: 700px) {
    width: 100%;
  }
`

// #0468d6
const PageHeaderInner = styled.div`
  background: rgba(16, 112, 202, 0.09);
  padding: 1rem;
`

const PageHeader = styled.header`
  /* margin: 1rem auto; */
  padding: 0 0 0.5rem;
`

const List = styled.div`
  flex: 1;
  overflow: auto;
  margin-top: 0.4rem;
`
// const ListItem = styled.div``

type Task = {
  internalId: string
  date?: string
  file?: File
  stage: UploadStage
  progress: number
  abort?: () => void
  error?: string // 上传失败
}

const addOrUpdateField = (field: Task, fields: Array<Task>): Array<Task> => {
  const index = fields.findIndex((f: Task) => field.internalId === f.internalId)
  // Not found, add on end.
  if (index === -1) {
    return [...fields, field]
  }
  // found, so return:
  // Clone of items before item being update.
  // updated item
  // Clone of items after item being updated.
  return [...fields.slice(0, index), field, ...fields.slice(index + 1)]
}

interface State {
  done: boolean
  uploading: boolean
  tasks: Task[]
  error?: string
}

const initState: State = {
  done: false,
  uploading: false,
  tasks: [],
}

type Action =
  | { type: 'STARTOVER' }
  | { type: 'SET_UPLOADING'; uploading: boolean }
  | { type: 'SET_DONE'; done: boolean }
  | { type: 'SET_TASK_SGATE'; id: string; stage: UploadStage }
  | { type: 'SET_TASK_FAIL'; id: string; error: string }
  | { type: 'SET_TASK_PROGRESS'; id: string; progress: number }
  | { type: 'SET_TASK_ABORT'; id: string; abort: () => void }
  | { type: 'DELETE_TASK'; id: string }
  | { type: 'ABORT_TASK'; id: string }
  | { type: 'NEW_TASK'; file: File }

const reducer = (prevState: State, action: Action): State => {
  if (!prevState) {
    return initState
  }
  switch (action.type) {
    case 'SET_DONE':
      return {
        ...prevState,
        done: action.done,
      }
    case 'SET_UPLOADING':
      return {
        ...prevState,
        uploading: action.uploading,
      }
    case 'SET_TASK_SGATE': {
      const task0 = prevState.tasks.find((t) => t.internalId === action.id)
      if (!task0) {
        return prevState
      }
      return {
        ...prevState,
        tasks: addOrUpdateField(
          {
            ...task0,
            stage: action.stage,
          },
          prevState.tasks
        ),
      }
    }
    case 'SET_TASK_PROGRESS': {
      const task0 = prevState.tasks.find((t) => t.internalId === action.id)
      if (!task0) {
        return prevState
      }
      return {
        ...prevState,
        tasks: addOrUpdateField(
          {
            ...task0,
            progress: action.progress,
          },
          prevState.tasks
        ),
      }
    }
    case 'SET_TASK_ABORT': {
      const task0 = prevState.tasks.find((t) => t.internalId === action.id)
      if (!task0) {
        return prevState
      }
      return {
        ...prevState,
        tasks: addOrUpdateField(
          {
            ...task0,
            abort: action.abort,
          },
          prevState.tasks
        ),
      }
    }
    case 'SET_TASK_FAIL': {
      const task0 = prevState.tasks.find((t) => t.internalId === action.id)
      if (!task0) {
        return prevState
      }
      return {
        ...prevState,
        tasks: addOrUpdateField(
          {
            ...task0,
            error: action.error,
            stage: 'error',
          },
          prevState.tasks
        ),
      }
    }
    case 'DELETE_TASK':
      return {
        ...prevState,
        tasks: [...prevState.tasks.filter((t) => t.internalId !== action.id)],
      }
    case 'NEW_TASK': {
      const internalId = `id-${Math.random()}`
      let overSize: boolean = false
      if (action.file) {
        const { size } = action.file
        if (size > MAX_FILE_SIZE) {
          overSize = true
        }
      }
      const error = overSize
        ? `文件超过限制大小(${byteToText(MAX_FILE_SIZE)})`
        : undefined
      const stage = overSize ? 'error' : 'pending'
      return {
        ...prevState,
        tasks: [
          {
            internalId,
            file: action.file,
            progress: 0,
            stage,
            error,
          },
          ...prevState.tasks,
        ],
      }
    }
    case 'ABORT_TASK': {
      const task0 = prevState.tasks.find((t) => t.internalId === action.id)
      if (!task0 || !task0.abort) {
        return prevState
      }
      task0.abort()
      return {
        ...prevState,
        tasks: addOrUpdateField(
          {
            ...task0,
            stage: 'aborted',
          },
          prevState.tasks
        ),
      }
    }
    case 'STARTOVER':
      return {
        ...initState,
      }
    default:
      return prevState
  }
}

const Home = () => {
  // const [tasks, setTasks] = useState<Task[]>([])
  const fileInput = React.createRef<HTMLInputElement>()
  const [state, dispatch] = useReducer(reducer, initState)

  const addFile = () => {
    fileInput.current?.click()
  }

  const deleteTask = (task: Task) => () => {
    dispatch({
      type: 'DELETE_TASK',
      id: task.internalId,
    })
  }

  const abortTask = (task: Task) => () =>
    dispatch({
      type: 'ABORT_TASK',
      id: task.internalId,
    })

  const handleChange = (files: FileList) => {
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i]
      dispatch({ type: 'NEW_TASK', file })
    }
  }

  const handleUpload = () => {
    const requests = state.tasks
      .filter((t) => t.stage === 'pending')
      .filter((t) => !!t.file)
      .map((task) => {
        dispatch({
          type: 'SET_TASK_SGATE',
          id: task.internalId,
          stage: 'uploading',
        })

        // CancelToken.
        const cancelSource = axios.CancelToken.source()
        dispatch({
          type: 'SET_TASK_ABORT',
          id: task.internalId,
          abort: () => {
            cancelSource.cancel()
          },
        })

        const form = new FormData()
        form.append('file', task.file!)
        return request
          .post(UPLOAD_API, form, {
            onUploadProgress: (e: ProgressEvent) => {
              const percentCompleted = Math.round((e.loaded * 100) / e.total)
              dispatch({
                type: 'SET_TASK_PROGRESS',
                id: task.internalId,
                progress: percentCompleted,
              })
            },
            cancelToken: cancelSource.token,
          })
          .then(() => {
            dispatch({
              type: 'SET_TASK_SGATE',
              id: task.internalId,
              stage: 'done',
            })
          })
          .catch((err) => {
            dispatch({
              type: 'SET_TASK_FAIL',
              id: task.internalId,
              error: `${err}`,
            })
            throw err
          })
      })

    dispatch({ type: 'SET_UPLOADING', uploading: true })

    axios
      .all(requests)
      .then(() => {
        dispatch({ type: 'SET_UPLOADING', uploading: false })
        dispatch({ type: 'SET_DONE', done: true })
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
      .finally(() => {
        // eslint-disable-next-line no-console
        console.log('finally !')
        dispatch({ type: 'SET_UPLOADING', uploading: false })
      })
  }

  const renderUploadButton = () => {
    if (state.tasks.length === 0) {
      return (
        <Button disabled onClick={() => {}}>
          上传
        </Button>
      )
    }
    return (
      <Button
        appearance="primary"
        disabled={state.uploading}
        onClick={handleUpload}
      >
        上传
      </Button>
    )
  }

  const startOver = () => dispatch({ type: 'STARTOVER' })

  if (state.done) {
    return (
      <Page>
        <Pane
          display="flex"
          padding={16}
          background="rgba(16, 112, 202, 0.09)"
          borderRadius={3}
        >
          <Pane flex={1} alignItems="center" display="flex">
            <Heading size={600}>上传成功</Heading>
          </Pane>
          <Pane>
            <Button appearance="primary" intent="success" onClick={startOver}>
              继续上传
            </Button>
          </Pane>
        </Pane>
      </Page>
    )
  }

  return (
    <Page>
      <PageHeader>
        <PageHeaderInner>
          <Pane display="flex">
            <Pane flex={1} alignItems="center" display="flex">
              <input
                ref={fileInput}
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => {
                  if (e.target.files) {
                    handleChange(e.target.files)
                  }
                }}
              />
              <Button onClick={() => addFile()} disabled={state.uploading}>
                添加文件
              </Button>
            </Pane>
            <Pane>{renderUploadButton()}</Pane>
          </Pane>
        </PageHeaderInner>
      </PageHeader>
      <List>
        {state.tasks.length > 0 ? (
          state.tasks.map((t) => (
            <Item
              key={t.internalId}
              file={t.file!}
              progress={t.progress}
              stage={t.stage}
              error={t.error}
              onDelete={deleteTask(t)}
              onAbort={abortTask(t)}
            />
          ))
        ) : (
          <>
            <Alert
              intent="none"
              title={`单个文件最大不超过 ${byteToText(MaxFileSizeInBytes)}`}
              marginBottom={32}
            />
          </>
        )}
      </List>
    </Page>
  )
}

export default Home
