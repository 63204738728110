/* eslint-disable no-bitwise */
export const randomUsername = (prefix?: string) => {
  const s = ((Math.random() * 0xffffff) << 0).toString(16)
  return `${prefix}${s}`
}

export const randomNoteId = (prefix?: string) => {
  const s = ((Math.random() * 0xffffff) << 0).toString(16)
  return `${prefix}${s}`
}
