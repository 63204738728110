import { NoteActionTypes, SystemState } from './types'
import { randomNoteId } from '../../util/random'
import { DELETE_NOTE } from './actionTypes'

const initialState = {
  note: {
    notes: [],
  },
} as SystemState

export default (
  state = initialState.note,
  action: NoteActionTypes
): typeof initialState.note => {
  switch (action.type) {
    case 'LOAD_NOTES_SUCCESS':
      return {
        ...state,
        notes: [...action.payload.notes],
      }
    case 'NEW_NOTE':
      return {
        ...state,
        notes: [
          {
            id: `${randomNoteId('note-')}`,
            content: action.payload.content,
            date: new Date().toISOString(),
            creator: action.payload.creator,
            receiver: action.payload.receiver,
          },
          ...state.notes,
        ],
      }
    case DELETE_NOTE:
      return {
        ...state,
        notes: [...state.notes.filter((n) => n.id !== action.payload.id)],
      }
    default:
      return state
  }
}
