import React, { useState, FC, useCallback } from 'react'
import styled from 'styled-components'

const PageBody = styled.div`
  flex: 1;
  overflow: auto;
`

const Page = styled.div`
  width: 650px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  height: 100%;
`

const Section = styled.section`
  h4 {
    position: sticky;
    background-color: #ecf0f1;
    top: 0;
    padding: 0.6rem 0;
    margin: 0;
  }
`

const Item = styled.div`
  height: 80px;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  p {
    margin: 0;
  }
`

type DummyItemType = {
  id: string
  date: string
}

const ListItem: FC = ({ children }) => {
  return <Item>{children}</Item>
}

// const AddButton: FC<{ onClick?: () => void }> = React.memo(
//   ({ onClick = () => {} }) => {
//     console.log(`AddButton render ...`)
//     return (
//       <button type="button" onClick={onClick}>
//         add
//       </button>
//     )
//   }
// )

const reDate = /(?<fullDate>(?<year>\d{4})-(?<month>\d{1,2})-(?<date>\d{1,2}))/u

const History = () => {
  const [items, setItems] = useState<DummyItemType[]>([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAdd = useCallback(() => {
    setItems((items0) => [
      {
        id: `id-${Math.random()}`,
        date: `${reDate.exec(new Date().toISOString())?.groups?.fullDate}`,
      },
      ...items0,
    ])
  }, [])

  return (
    <Page>
      <PageBody>
        {/* <AddButton onClick={handleAdd} /> */}
        <Section>
          <h4>今日</h4>
          {items.map((item) => (
            <ListItem key={item.id}>
              <p>{item.date}</p>
            </ListItem>
          ))}
        </Section>
      </PageBody>
    </Page>
  )
}

export default History
