import React, { useState, useMemo, Dispatch } from 'react'
import styled from 'styled-components'
import RichTextEditor, { EditorValue } from 'react-rte'
import { Button } from 'evergreen-ui'
import { useSelector, useDispatch } from 'react-redux'
// import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill' // ES6

import './style.scss'
import { selectors as sessionSelectors } from '../../features/session'
import request from '../../util/request'
import { NoteActionTypes } from '../../features/note/types'
import NoteList from './components/NoteList'
import { API_ROOT } from '../../consts'

const UPLOAD_API = `${API_ROOT}/upload`
const WIDTH = '650px'
const SmBreakpoint = '700px'

const PageBodyInner = styled.div`
  width: ${WIDTH};
  margin: 0 auto;
  @media (max-width: ${SmBreakpoint}) {
    width: auto;
  }
`

const PageBody = styled.div`
  flex: 1;
  overflow: auto;
`

const PageHeader = styled.div`
  padding-bottom: 0.8rem;
`

const PageHeaderInner = styled.div`
  background: rgba(16, 112, 202, 0.09);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 55px;
  width: ${WIDTH};
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: ${SmBreakpoint}) {
    width: auto;
  }
`

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const reYMD = /(?<Y>\d{4})-(?<MM>\d{2})-(?<DD>\d{2})T(?<HH>\d{2}):(?<mm>\d{2})/u

const getRandomFilename = () => {
  const t = new Date()
  // eslint-disable-next-line no-bitwise
  const rnd = ((Math.random() * 0xffffff) << 0).toString(16)
  const g = (reYMD.exec(t.toISOString())?.groups || {}) as any
  return `${g.Y}${g.MM}${g.DD}${g.HH}${g.mm}-${rnd}`
}

type EditorStage = 'editing' | 'saving' | 'saved'

const Note = () => {
  const user = useSelector(sessionSelectors.getUser)

  const tmpFilename = `${user?.login}-${getRandomFilename()}.html`
  const postTime = new Date().toISOString()

  const [contentHtml, setContentHtml] = useState('')
  const [editorStage, setEditorStage] = useState<EditorStage>('editing')

  const dispatch = useDispatch<Dispatch<NoteActionTypes>>()

  const canSubmit = contentHtml.length > '<p><br></p>'.length
  const handleSubmit = React.useCallback(
    (e: MouseEvent) => {
      e.preventDefault()

      const html = contentHtml

      const div = document.createElement('div')
      div.innerHTML = html
      const desc = div.innerText.slice(0, 140).replace(/\s/g, ' ')

      const text = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" href="//xzd.co/uploadokku/assets/css/html.css" type="text/css" />
        <title>Direct message from ${user?.nickname}</title>
        <meta property="description" content="${desc}" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="From ${user?.nickname}" />
        <meta property="og:description" content="${desc}" />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:title" content="From ${user?.nickname}" />
        <meta property="twitter:description" content="${desc}" />
        <meta property="twitter:creator" content="${user?.nickname}" />
      </head>
      <body>
        <div class="article">
        ${html}
        </div>
        <footer>
          <p>file: ${tmpFilename}</p>
          <p>time: ${postTime}</p>
          <p class="author">
          <strong>${user?.nickname}</strong> ${user?.login} ${user?.uid}</p>
        </footer>
      </body>
    </html>`

      const theBlob = new Blob([text], {
        type: 'text/html',
      })

      const file = new File([theBlob], tmpFilename, { type: theBlob.type })

      const formData = new FormData()
      formData.set('file', file)

      // 正在保存
      setEditorStage('saving')

      request
        .post(UPLOAD_API, formData)
        .then((resp) => resp.data)
        .then(() => {
          setEditorStage('saved')

          dispatch({
            type: 'NEW_NOTE',
            payload: {
              content: contentHtml,
              creator: 'zd',
              receiver: '*',
            },
          })
        })
        .catch(() => {
          setEditorStage('editing')
        })
    },
    [contentHtml, dispatch, postTime, tmpFilename, user]
  )

  const handleStartover = (e: MouseEvent) => {
    e.preventDefault()
    setEditorStage('editing')
    setContentHtml('')
  }

  const buttons = useMemo(() => {
    switch (editorStage) {
      case 'editing':
        return (
          <Button
            onClick={handleSubmit}
            appearance="primary"
            disabled={!canSubmit}
          >
            提交
          </Button>
        )
      case 'saving':
        return (
          <Button disabled appearance="default">
            正在提交...
          </Button>
        )
      case 'saved':
        return (
          <Button
            onClick={handleStartover}
            appearance="primary"
            intent="success"
          >
            提交成功，继续写
          </Button>
        )
      default:
        return `??${editorStage}??`
    }
  }, [editorStage, canSubmit, handleSubmit])

  return (
    <Page>
      <PageHeader>
        <PageHeaderInner>
          <div style={{ padding: '0', color: '#999' }}>阅后即焚</div>
          {buttons}
        </PageHeaderInner>
      </PageHeader>
      <PageBody>
        <PageBodyInner>
          {editorStage === 'editing' ? (
            <div>
              <MyEditor initialValue={contentHtml} onChange={setContentHtml} />
              {/* <div>{editorValue.toString('html')}</div> */}
            </div>
          ) : (
            <></>
          )}
          <NoteList />
        </PageBodyInner>
      </PageBody>
    </Page>
  )
}

export default Note

const MyEditor: React.FC<{
  initialValue: string
  onChange: (content: string) => any
}> = React.memo(({ initialValue, onChange }) => {
  const [editorValue, setEditorValue] = React.useState(
    RichTextEditor.createValueFromString(initialValue, 'html')
  )

  const handleEditorChange = (value: EditorValue) => {
    setEditorValue(value)
    onChange(value.toString('html'))
  }

  return (
    <RichTextEditor
      autoFocus
      placeholder="Tell me something... (Click to write)"
      value={editorValue}
      editorClassName="myeditor"
      onChange={handleEditorChange}
    />
  )
})
