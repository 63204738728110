import React, { Dispatch, useState, ChangeEvent } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { Button, Pane, TextInputField } from 'evergreen-ui'
import qs from 'query-string'
import { useDispatch } from 'react-redux'
import axios from 'axios'

import { SessionActionTypes } from '../../features/session/types'

const PageScopeStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  #root {
    overflow: hidden;
  }
  body {
    padding: 1px;
    background-color: #f7f7f7;
    background-image: url(https://cn.bing.com/ImageResolution.aspx?w=1920);
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media
  (-webkit-min-device-pixel-ratio: 2), 
  (min-resolution: 192dpi) {
    body {
      background-image: url(https://cn.bing.com/ImageResolution.aspx?w=3840);
    }
  }
`

const ScreenContainer = styled.div`
  width: 650px;
  margin: 30px auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    padding: 35px 0;
    h1 {
      /* color: #f56a6a; */
      color: #fff;
    }
  }
  .take-action {
    padding: 35px 0 35px;
  }

  .dribbble-logo {
    width: 360px;
  }

  @media (max-width: 650px) {
    padding: 20px 0;
    width: 90%;
    .title {
      padding: 10px 0;
    }

    .dribbble-logo {
      width: 300px;
    }
  }

  @media (max-width: 400px) {
    padding: 10px 0;
    width: 90%;
    .title {
      padding: 10px 0;
    }

    .dribbble-logo {
      width: 240px;
    }
  }
`

type InviteCodeResp =
  | {
      success: true
      data: string[]
    }
  | {
      success: false
    }

const verifyInviteCode = async (
  inviteCode: string
): Promise<string | undefined> => {
  // 本地测试忽略 invite code
  if (/localhost/gi.test(window.location.host)) {
    return undefined
  }

  // TODO: API 需要重构
  const prom = await axios
    .get<InviteCodeResp>(`/api/get-invite-codes`)
    .then((resp) => resp.data)
    .then((data) => {
      if (!data.success) {
        return '邀请码请求不成功'
      }
      if (data.data?.length === 0) {
        return '邀请码用完了'
      }
      if (data.data.findIndex((c) => c === inviteCode) === -1) {
        return '邀请码过期了或不存在'
      }
      return undefined
    })
    .catch((err) => {
      /* console.error(err) */
      return `出错了: ${err}`
    })
  return prom
}

const LockScreen: React.FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch<Dispatch<SessionActionTypes>>()

  const [state, setState] = useState<Record<'nickname' | 'inviteCode', string>>(
    {
      nickname: '',
      inviteCode: '',
    }
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'nickname' || name === 'inviteCode') {
      setState({
        ...state,
        [name]: value,
      })
    }
  }

  const handleSignup = () => {
    const { nickname, inviteCode } = state

    if (!inviteCode) {
      // eslint-disable-next-line no-alert
      window.alert('邀请码必填')
      return
    }

    verifyInviteCode(inviteCode).then((err) => {
      if (!err) {
        // 找到了
        dispatch({
          type: 'SIGNUP_USER',
          payload: {
            nickname,
          },
        })
        const QS = qs.parse(location.search) as {
          return_to?: string
        }
        history.push(QS.return_to || '/')
        return
      }
      // eslint-disable-next-line no-alert
      alert(err || '注册不成功')
    })
  }

  // https://xzd.co/get-invite-codes
  return (
    <>
      <PageScopeStyle />
      <ScreenContainer>
        <div className="title">
          <h1>你还没有账号</h1>
        </div>
        <Pane
          display="flex"
          background="tint2"
          borderRadius={3}
          width={330}
          alignItems="center"
          justifyContent="center"
          paddingY={30}
        >
          <Pane>
            <TextInputField
              name="nickname"
              label="注册一个临时用户"
              placeholder="取个好名字"
              onChange={handleChange}
            />
            <TextInputField
              name="inviteCode"
              label="邀请码"
              placeholder="一定会有办法的"
              onChange={handleChange}
            />
          </Pane>
        </Pane>
        <div className="take-action">
          <Button
            appearance="primary"
            intent="success"
            iconAfter="arrow-right"
            onClick={() => handleSignup()}
          >
            注册
          </Button>
        </div>
      </ScreenContainer>
    </>
  )
}

export default LockScreen
