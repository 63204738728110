import React, { useState, FC, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Icon, Button, Pane, InfoSignIcon, Tooltip } from 'evergreen-ui'

import './Item.scss'
import { byteToText } from '../../../util/fmt'

const ItemBox = styled.div`
  background-color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &::after {
    position: absolute;
    display: none;
    content: ' ';
    font-size: 0;
    height: 5px;
    width: 65px;
    background-color: #fff;
    top: -2px;
    left: 0;
    z-index: 10;
  }
  & + & {
    border-top: 1px solid #f0f0f0;
    &::after {
      display: block;
    }
  }
`

const ItemIcon = styled.div`
  flex: 0 0 50px;
`

const ItemTitle = styled.div`
  flex: 1;
  h4 {
    margin: 0;
    padding: 0.3em 0;
    word-break: break-all;
  }
  p {
    margin: 0;
    padding: 0.2em 0;
    color: #797979;
  }
`

const ProgressBar = styled.div<{ progress: number }>`
  background-color: #ff714a;
  height: 100%;
  width: ${(props) => `${props.progress}%`};
`

const Progress = styled.div`
  background-color: #d4d4d4;
  height: 32px;
  width: 60px;
`

export type UploadStage = 'pending' | 'uploading' | 'aborted' | 'done' | 'error'

type UploadTask = {
  progress: number
  file: File
  stage: UploadStage
}

const Item: FC<{
  file: File
  progress?: number
  stage?: UploadStage
  error?: string
  onDelete?: () => void
  onAbort?: () => void
}> = ({ file, onDelete, onAbort, progress = 0, stage = 'pending', error }) => {
  const [task, setTask] = useState<UploadTask>({
    progress,
    stage,
    file,
  })

  useEffect(() => {
    // eslint-disable-next-line no-console
    // console.log(
    //   `Home Item useEffect .... ${task.stage}  ${task.progress} ${file.name}`
    // )
    setTask({ ...task, stage, progress })
  }, [stage, progress, task])

  // eslint-disable-next-line no-console
  console.log(
    `Home Item rendering .... ${task.stage}  ${task.progress} ${file.name}`
  )

  const renderAction = useCallback(() => {
    switch (task.stage) {
      case 'pending':
        return (
          <Button appearance="minimal" intent="danger" onClick={onDelete}>
            删除
          </Button>
        )
      case 'uploading':
        return (
          <Pane display="flex">
            <Pane paddingX={6}>
              <Progress>
                <ProgressBar progress={task.progress} />
              </Progress>
            </Pane>
            <Pane>
              <Button
                appearance="minimal"
                intent="danger"
                iconBefore="cross"
                onClick={onAbort}
              >
                取消
              </Button>
            </Pane>
          </Pane>
        )
      case 'aborted':
        return (
          <Button appearance="minimal" disabled>
            已取消
          </Button>
        )
      case 'error':
        return (
          <>
            <Pane display="flex" alignItems="center" justifyContent="center">
              <span
                style={{
                  color: 'red',
                  paddingRight: '.3em',
                  lineHeight: '20px',
                }}
              >
                出错
              </span>
              <Tooltip content={`${error}`}>
                <InfoSignIcon color="danger" />
              </Tooltip>
            </Pane>
          </>
        )
      case 'done':
        return <span>完成</span>
      default:
        return <span>??{task.stage}??</span>
    }
  }, [task, error, onAbort, onDelete])

  return (
    <ItemBox>
      <ItemIcon>
        <Icon color="#d4d4d4" size={24} icon="document" />
      </ItemIcon>
      <ItemTitle>
        <h4>{task.file.name}</h4>
        <p>
          大小: <span>{byteToText(task.file.size)}</span>
        </p>
      </ItemTitle>
      <div>{renderAction()}</div>
    </ItemBox>
  )
}

export default React.memo(Item)
