import React, { Dispatch } from 'react'
import styled from 'styled-components'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Pane, Button } from 'evergreen-ui'
import { SessionActionTypes } from '../../features/session/types'

const Container = styled.div`
  width: 650px;
  margin: 0 auto;
  @media (max-width: 700px) {
    width: 100%;
  }
`

const NavTab = styled.div`
  flex: 1;
  a {
    display: block;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    color: #f0f0f0;
    &:hover {
      color: #fff;
    }
    &.active {
      color: #384459;
      background-color: #fff;
    }
    &.active:hover {
      background-color: #f7f7f7;
    }
  }
`

const NavTabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #91989e;
  border-radius: 4px;
  padding: 4px;
  margin-top: 0.5rem;
`

const Navbar = () => {
  const dispatch = useDispatch<Dispatch<SessionActionTypes>>()
  const h = useHistory()
  const handleLogout = () => {
    dispatch({ type: 'INVALIDATE_USER' })
    h.push('/')
  }

  return (
    <Container>
      <Pane display="flex" alignItems="center" justifyContent="space-between">
        <Pane />
        <Pane width={260}>
          <NavTabs>
            <NavTab>
              <NavLink exact to="/">
                传文件
              </NavLink>
            </NavTab>
            {/* <NavTab>
          <NavLink to="/history">历史记录</NavLink>
        </NavTab> */}
            <NavTab>
              <NavLink to="/note">传文本</NavLink>
            </NavTab>
          </NavTabs>
        </Pane>
        <Pane display="flex" alignItems="center">
          <Button appearance="minimal" onClick={handleLogout}>
            退出
          </Button>
        </Pane>
      </Pane>
    </Container>
  )
}

export default Navbar
