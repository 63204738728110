import { IncomingMessage } from './events'

export class Client {
  timeout = 250

  ws?: WebSocket

  stopped = false

  connect = () => {
    const ws = new WebSocket(`ws://localhost:3131/room/general`)
    let connectInterval = -1

    ws.onopen = () => {
      // eslint-disable-next-line no-console
      console.log(`ws conneced`)
      this.ws = ws
      this.timeout = 250
      clearInterval(connectInterval)
    }

    ws.onclose = () => {
      if (this.stopped) {
        return
      }
      this.timeout *= 2 // 增加 retry interval
      connectInterval = setTimeout(this.check, Math.min(10000, this.timeout))
    }

    ws.onmessage = this.incomingMessage

    ws.onerror = (err) => {
      // eslint-disable-next-line no-console
      console.error('Socket encountered error: ', err, 'Closing socket')
      ws.close()
    }
  }

  incomingMessage = (evt: MessageEvent) => {
    try {
      const msg = JSON.parse(`${evt.data}`) as IncomingMessage

      if (!msg) {
        return
      }

      switch (msg.type) {
        case 'health.check':
          this.ws?.send(
            JSON.stringify({
              type: 'health.check',
            })
          )
          break
        default:
      }
    } catch (err) {
      // console.error(err)
    }
  }

  check = () => {
    const { ws } = this
    if (!ws || ws.readyState === WebSocket.CLOSED) {
      this.connect()
    }
  }

  shutdown = () => {
    this.stopped = true
    if (!this.ws || this.ws.readyState !== WebSocket.CONNECTING) {
      return
    }
    this.ws.close()
  }
}
