import React from 'react'
import styled from 'styled-components'
import { ReactComponent as IconHeart } from '../../assets/icons/heart.svg'

const pkgVersion = process.env.REACT_APP_VERSION || '-'

const PageFooter = styled.div`
  flex: 0 0 26px;
  line-height: 26px;
  text-align: center;
  color: #555;
  .icon {
    margin: 0 0.3rem;
  }
  .icon svg {
    vertical-align: middle;
  }
  .icon-red svg {
    fill: #f1274e;
  }
`

const Footer = () => {
  return (
    <PageFooter>
      Made by ZD with
      <span className="icon icon-red">
        <IconHeart />
      </span>
      <span>v{pkgVersion}</span>
    </PageFooter>
  )
}

export default Footer
