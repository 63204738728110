import { SystemState, SessionActionTypes, User } from './types'
import { LOAD_USER_SUCCESS, SIGNUP_USER, INVALIDATE_USER } from './actionTypes'
import { UUIDv4 } from '../../util/uuid'
import { randomUsername } from '../../util/random'

const initialState = {
  session: {
    user: undefined,
  },
} as SystemState

export default (
  state = initialState.session,
  action: SessionActionTypes
): typeof initialState.session => {
  switch (action.type) {
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
      }
    case SIGNUP_USER: {
      const { nickname } = action.payload
      const user = {
        type: 'user',
        uid: UUIDv4(),
        login: randomUsername('u2020-'),
        nickname,
        createdAt: new Date().toISOString(),
      } as User
      return {
        ...state,
        user,
      }
    }
    case INVALIDATE_USER:
      return {
        ...state,
        user: undefined,
      }
    default:
      return state
  }
}
