import React, { useEffect, Dispatch, useState } from 'react'
import { Switch, Route } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import './App.css'
import { withBasicLayout } from './components/Layout/BasicLayout'
import Home from './pages/Home'
import History from './pages/History'
import LockScreen from './pages/LockScreen/LockScreen'
import Note from './pages/Note'
import AuthorizedRouteHOC from './hoc/AuthorizedRouteHOC'

import { selectors as sessionSelectors } from './features/session'
import { User, SessionActionTypes } from './features/session/types'

import { selectors as noteSelectors } from './features/note'
import { NoteActionTypes, Note as NoteType } from './features/note/types'
import Chat from './pages/Chat'

const NAMES = {
  SESSION: '__session__',
  NOTES: '__notes__',
  UID: '__uid__',
}

function App() {
  const dispatch = useDispatch<Dispatch<SessionActionTypes | NoteActionTypes>>()
  // const sessionUser = useSelector(sessionSelectors.getUser)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem(NAMES.SESSION) || '') as User
      if (user.uid) {
        dispatch({
          type: 'LOAD_USER_SUCCESS',
          user,
        })
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }

    try {
      const n = JSON.parse(
        localStorage.getItem(NAMES.NOTES) || ''
      ) as NoteType[]
      if (n) {
        dispatch({
          type: 'LOAD_NOTES_SUCCESS',
          payload: {
            notes: n,
          },
        })
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }

    setReady(true)
  }, [dispatch])

  const user = useSelector(sessionSelectors.getUser)
  const notes = useSelector(noteSelectors.getNotes)

  useEffect(() => {
    // const unsubscribe = store.subscribe(() => {
    //   const u = sessionSelectors.getUser(store.getState())
    //   if (u && u.nickname) {
    //     localStorage.setItem(
    //       NAMES.SESSION,
    //       JSON.stringify(sessionSelectors.getUser(store.getState()))
    //     )
    //   } else {
    //     localStorage.removeItem(NAMES.SESSION)
    //   }
    //   const notes = noteSelectors.getNotes(store.getState())
    //   if (notes) {
    //     localStorage.setItem
    //   }
    // })
    // return () => unsubscribe()

    // eslint-disable-next-line no-console
    console.log(`save usr ... ${JSON.stringify(user)}`)

    localStorage.setItem(NAMES.SESSION, JSON.stringify(user))
  }, [user])

  useEffect(() => {
    localStorage.setItem(NAMES.NOTES, JSON.stringify(notes))
  }, [notes])

  if (!ready) {
    return <p>Loading ...</p>
  }

  return (
    <Switch>
      <AuthorizedRouteHOC path="/" exact component={withBasicLayout(Home)} />
      <AuthorizedRouteHOC
        path="/history"
        component={withBasicLayout(History)}
      />
      <Route path="/lock" component={LockScreen} />
      <AuthorizedRouteHOC path="/note" component={withBasicLayout(Note)} />
      <AuthorizedRouteHOC path="/chat" component={Chat} />
    </Switch>
  )
}

export default App
