import React from 'react'
import styled from 'styled-components'
import Navbar from './Navbar'
import Footer from '../Footer/intex'

const LayoutNav = styled.aside`
  flex: 0 0 60px;
`

const LayoutMain = styled.main`
  flex: 1;
  overflow: auto;
`

const Layout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const BasicLayout: React.FC<{}> = ({ children }) => {
  return (
    <Layout>
      <LayoutNav>
        <Navbar />
      </LayoutNav>
      <LayoutMain>{children}</LayoutMain>
      <Footer />
    </Layout>
  )
}

export default BasicLayout

export function withBasicLayout<T>(Component: React.ComponentType<T>) {
  return (props: T) => (
    <BasicLayout>
      <Component {...props} />
    </BasicLayout>
  )
}
